import React from 'react';

import tradeBg from '../../../assets/img/trade/trade-bg-img.png';
import tradePhone from '../../../assets/img/trade/trade-phone-img.png';
import btnArrowIcon from '../../../assets/img/btn-arrow-icon.svg';

const Trade = () => (
    <section id="trade" className="trade-section">
        <div className="bg-wrap">
            <img src={tradeBg} alt="Bg"/>
        </div>
        <div className="content-wrap">
            <div className="text-wrap">
                <h1>Trade like<br/> a pro with<br/> ApeScreener</h1>
                <h6>Leave the times of emotional trading behind.</h6>
            </div>
            <div className="btn-wrap">
                <a href="https://apescreener.app" target="_blank" rel="noreferrer" className="btn-main">
                    <span className="text">Set Up Free Adviser Now</span>
                    <span className="icon">
                        <img src={btnArrowIcon} alt="Icon"/>
                    </span>
                </a>
            </div>
        </div>
        <div className="phone-wrap">
            <img src={tradePhone} alt="phone"/>
        </div>
    </section>
);

export default Trade;
