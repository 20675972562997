import dAppImg from "../../assets/img/partnership/dapp-img.png";
import WorldRedIcon from "../../assets/img/partnership/world-icon.svg";
import XIcon from "../../assets/img/partnership/x-icon.svg";
import partnerLogoPlaceholder from "../../assets/img/partnership/partner-logo-placeholder.png";
import { useEffect, useMemo, useState } from "react";

const partners = [];
// const partners = [
//   {
//     id: 1,
//     name: "BribeAI",
//     description:
//       "BribeAi is your go-to platform for navigating Web3 launches with confidence.",
//     logo: partnerLogoPlaceholder,
//     xLink: "https://x.com/apescreener",
//     wordWideWebLink: "https://apescreener.xyz",
//   },
//   {
//     id: 2,
//     name: "Partner 2",
//     description:
//       "BribeAi is your go-to platform for navigating Web3 launches with confidence. BribeAi is your go-to platform for navigating Web3 launches with confidence.",
//     logo: partnerLogoPlaceholder,
//     xLink: "https://x.com/apescreener",
//     wordWideWebLink: "https://apescreener.xyz",
//   },
//   {
//     id: 3,
//     name: "Partner 3",
//     description:
//       "BribeAi is your go-to platform for navigating Web3 launches with confidence.",
//     logo: partnerLogoPlaceholder,
//     xLink: "https://x.com/apescreener",
//     wordWideWebLink: "https://apescreener.xyz",
//   },
//   {
//     id: 4,
//     name: "Partner 3",
//     description:
//       "BribeAi is your go-to platform for navigating Web3 launches with confidence.",
//     logo: partnerLogoPlaceholder,
//     xLink: "https://x.com/apescreener",
//     wordWideWebLink: "https://apescreener.xyz",
//   },
//   {
//     id: 5,
//     name: "Partner 3",
//     description:
//       "BribeAi is your go-to platform for navigating Web3 launches with confidence.",
//     logo: partnerLogoPlaceholder,
//     xLink: "https://x.com/apescreener",
//     wordWideWebLink: "https://apescreener.xyz",
//   },
//   {
//     id: 6,
//     name: "Partner 3",
//     description:
//       "BribeAi is your go-to platform for navigating Web3 launches with confidence.",
//     logo: partnerLogoPlaceholder,
//     xLink: "https://x.com/apescreener",
//     wordWideWebLink: "https://apescreener.xyz",
//   },
// ];

const investmentPartners = [];
// const investmentPartners = [
//   {
//     id: 1,
//     logo: partnerLogoPlaceholder,
//   },
//   {
//     id: 2,
//     logo: partnerLogoPlaceholder,
//   },
//   {
//     id: 3,
//     logo: partnerLogoPlaceholder,
//   },
//   {
//     id: 4,
//     logo: partnerLogoPlaceholder,
//   },
//   {
//     id: 5,
//     logo: partnerLogoPlaceholder,
//   },
//   {
//     id: 6,
//     logo: partnerLogoPlaceholder,
//   },
// ];

const InvestmentPartnersSection = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const isMobile = useMemo(() => screenWidth <= 768, [screenWidth]);
  const [visibleItems, setVisibleItems] = useState(
    isMobile ? 3 : partners.length
  );

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  const handleShowMore = () => {
    setVisibleItems((prev) => prev + 3); // Show 3 more items
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (!investmentPartners.length) {
    return null;
  }

  return (
    <div className="investment-partners-section">
      <div className="section-title">Meet our Investment Partners</div>
      <div className="cards-wrapper">
        {investmentPartners.slice(0, visibleItems).map((partner) => (
          <div className="card" key={partner.id}>
            <img src={partner.logo} alt={`Partner ${partner.id}`} />
          </div>
        ))}
      </div>

      {visibleItems < investmentPartners.length && (
        <button className="show-more-btn" onClick={handleShowMore}>
          Show More
        </button>
      )}
    </div>
  );
};

const Partnership = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const isMobile = useMemo(() => screenWidth <= 768, [screenWidth]);
  const [visibleItems, setVisibleItems] = useState(
    isMobile ? 3 : partners.length
  );

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  const handleShowMore = () => {
    setVisibleItems((prev) => prev + 3); // Show 3 more items
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="partnership-page">
      <div className="partnership-hero">
        <div class="content">
          <div className="content-wrap">
            <div className="title">
              <div className="headline">Become our</div>
              <div className="headline">Partner</div>
              <div className="tagline">
                By partnering with ApeScreener, you join the innovative
                ecosystem of DeFi products, gain new users and connections.
              </div>
            </div>

            <div className="btn-wrap">
              <a href="/partnership/integration">
                <div className="btn integration">
                  <div className="text">Integration Partnership</div>
                </div>
              </a>
              <a href="/partnership/investment">
                <div className="btn investment">
                  <div className="text">Investment Partnership</div>
                </div>
              </a>
            </div>
          </div>
          <div className="app-image">
            <img src={dAppImg} alt="dApp screen" />
          </div>
        </div>
      </div>
      <div className="partnership-grow">
        <div className="content">
          <div className="title">
            <div className="line">Grow your project</div>
            <div className="line">with <span className="highlight">ApeScreener</span></div>
          </div>
        </div>
        <div className="steps">
          <div className="step">
            <div className="step-number">1</div>
            <div className="step-content">
              <div className="title">Innovate</div>
              <div className="description">
                Partner with ApeScreener to co-develop innovative features that
                meet the evolving needs of the DeFi space.
              </div>
            </div>
          </div>
          <div className="step">
            <div className="step-number">2</div>
            <div className="step-content">
              <div className="title">Strengthen Your Brand</div>
              <div className="description">
                Benefit from ApeScreener’s strong brand presence and reputation
                in the DeFi space to boost your project’s visibility.
              </div>
            </div>
          </div>
          <div className="step">
            <div className="step-number">3</div>
            <div className="step-content">
              <div className="title">Increase Market Awareness</div>
              <div className="description">
                Leverage our platform to introduce your project to new markets
                and demographics.
              </div>
            </div>
          </div>
        </div>
      </div>
      {partners.length && (<div className="partners-section">
        <div className="section-title">Meet our Integration Partners</div>
        <div className="cards-container">
          {partners.slice(0, visibleItems).map((partner) => (
            <div className="card" key={partner.id}>
              <div className="logo">
                <img src={partner.logo} alt={partner.name} />
              </div>
              <div className="card-content">
                <div className="title">{partner.name}</div>
                <div className="description">{partner.description}</div>
              </div>
              <div className="actions">
                {partner.wordWideWebLink && (
                  <a
                    className="icon-button"
                    href={partner.wordWideWebLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="icon">
                      <img src={WorldRedIcon} alt="World social link" />
                    </div>
                  </a>
                )}
                {partner.xLink && (
                  <a
                    className="icon-button"
                    href={partner.xLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="icon">
                      <img src={XIcon} alt="X social link" />
                    </div>
                  </a>
                )}
              </div>
            </div>
          ))}
        </div>

        {visibleItems < partners.length && (
          <button className="show-more-btn" onClick={handleShowMore}>
            Show More
          </button>
        )}
      </div>)}

      <InvestmentPartnersSection />
    </div>
  );
};

export default Partnership;
