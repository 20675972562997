import React from 'react';
import { Link } from 'react-router-dom';

import infoPageBg from '../../assets/img/info-page-bg.png';

const Privacy = () => (
  <main>
    <div className="info-page-section">
      <div className="bg-wrap">
        <img src={infoPageBg} alt="Bg" />
      </div>
      <div className="headline-wrap">
        <h2>
          Privacy Policy
          <br /> for Apescreener
        </h2>
        <h6>
          This privacy notice for ApeScreener ("we," "us," or "our"), describes how and why we might collect, store,
          use, and/or share ( "process") your information when you use our services ("Services"), such as when you:
        </h6>
        <h6>
          <p>
            <h6>
              1. Visit our website at <Link to="/">https://apescreener.xyz/</Link>
            </h6>
          </p>
          <p>
            <h6>
              2. Visit our dApp at{' '}
              <a href="https://apescreener.app" target="_blank" rel="noreferrer">
                https://apescreener.app/
              </a>
            </h6>
          </p>
        </h6>
        <h6>
          We may change this Privacy Policy from time to time so please check this page occasionally to ensure that you
          are happy with any changes. By using our Website or our Services, you are agreeing to be bound by this Privacy
          Policy.
        </h6>
      </div>
      <div className="text-wrap">
        <div className="item-headline">
          <h4>What information do we collect?</h4>
        </div>
        <div className="item">
          <p>
            We collect on-chain data such as wallet addresses and transaction details, which are stored publicly on the
            blockchain. Additionally, we may collect limited off-chain data like IP addresses or browser information to
            enhance user experience:
          </p>
          <ul>
            <li>
              <p>
                On-Chain Data: Public wallet addresses, transaction hashes, and related blockchain activity (this
                information is publicly available and stored on the blockchain)
              </p>
            </li>
            <li>
              <p>Off-Chain Data: Any additional information collected by the DApp, such as:</p>
              <ol>
                <li>
                  <p>IP addresses</p>
                </li>
                <li>
                  <p>Operating system</p>
                </li>
                <li>
                  <p>Browser or device information</p>
                </li>
                <li>
                  <p>Cookies or analytics data</p>
                </li>
                <li>
                  <p>Telegram username</p>
                </li>
                <li>
                  <p>Data usage (page visited, time spent on the site, frequency of use, links clicked)</p>
                </li>
              </ol>
            </li>
          </ul>
        </div>
        <div className="item-headline">
          <h4>How do we process your information?</h4>
        </div>
        <div className="item">
          <p>
            We use collected information to enable smart contract interactions, ensure the smooth operation of the DApp,
            and comply with applicable laws.
          </p>
          <ul>
            <li>
              <p>Facilitating DApp functions, such as token transactions or interacting with smart contracts</p>
            </li>
            <li>
              <p>Debugging and improving the DApp</p>
            </li>
            <li>
              <p>Compliance with legal and regulatory obligations</p>
            </li>
          </ul>
        </div>
        <div className="item-headline">
          <h4>Sharing of Information:</h4>
        </div>
        <div className="item">
          <p>
            We do not share or sell personal off-chain data with third parties except as required to operate the DApp or
            comply with legal obligations. On-chain data is inherently public and cannot be altered or restricted by us.
          </p>
          <p>
            When we use Google Analytics. We may share your information with Google Analytics to track and analyze the
            use of the Services. For more information on the privacy practices of Google, please visit the{' '}
            <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">
              Google Privacy & Terms page
            </a>
            . To opt out of being tracked by Google Analytics across the Services, visit
            <a href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noreferrer">
              https://tools.google.com/dlpage/gaoptout
            </a>
            . We share your information with Vercel as well to track and analyze the use of the Service. For more
            information, please visit{' '}
            <a href="https://vercel.com/docs/analytics" target="_blank" rel="noreferrer">
              https://vercel.com/docs/analytics
            </a>
            .
          </p>
        </div>
        <div className="item-headline">
          <h4>Security of Information:</h4>
          <p>
            We retain personal information as long as necessary to fulfill the purposes outlined in this Policy and
            comply with legal obligations. While we take reasonable measures to secure any off-chain data, transactions
            and information recorded on the blockchain are immutable and beyond our ability to alter or delete.
          </p>
        </div>
        <div className="item-headline">
          <h4>User Rights:</h4>
          <p>You can access and manage your personal information by contacting us. You may:</p>
          <ul>
            <li>
              <p>Review and update your information.</p>
            </li>
            <li>
              <p>Correct inaccuracies.</p>
            </li>
            <li>
              <p>Request to erase your information.</p>
            </li>
          </ul>
          <p>
            You can control the use of cookies through your browser settings. For more information, see our Cookies
            Policy. You may also wish to refer to this website for additional information about disabling cookies from
            your browser:{' '}
            <a href="http://www.allaboutcookies.org/manage-cookies" target="_blank" rel="noreferrer">
              http://www.allaboutcookies.org/manage-cookies/
            </a>
            .
          </p>
        </div>
        <div className="item-headline">
          <h4>Complaints:</h4>
        </div>
        <div className="item">
          <p>
            Should you wish to raise a concern about our use of your information (and without prejudice to any other
            rights you may have), you have the right to do so with your local supervisory authority, although we will be
            happy to assist with any queries or concerns you may have about our use of your personal data.
          </p>
        </div>
        <div className="item-headline">
          <h4>Children’s Privacy:</h4>
          <p>
            Our Services are not intended for children under the age of 13. We do not knowingly collect personal
            information from children under 13. If we become aware that we have inadvertently received such information,
            we will delete it promptly.
          </p>
        </div>
        <div className="item-headline">
          <h4>How can you contact us about this notice?</h4>
          <p>
            If you have questions or comments about this notice, you may email us at{' '}
            <a href="mailto:support@apescreener.xyz">support@apescreener.xyz</a>
          </p>
        </div>
        <div className="item-headline">
          <h4>Disclaimer:</h4>
          <p>TradingView Privacy Policy is unrelated to ApeScreener Privacy Policy.</p>
        </div>
      </div>
    </div>
  </main>
);

export default Privacy;
