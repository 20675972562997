import React from 'react';

import strategiesItemOne from '../../../assets/img/strategies/strategies-item-3.png';
import strategiesItemTwo from '../../../assets/img/strategies/strategies-item-2.png';
import strategiesItemThree from '../../../assets/img/strategies/strategies-item-1.png';
import strategiesBg from '../../../assets/img/strategies/strategies-bg-img.png';
import btnArrowIcon from '../../../assets/img/btn-arrow-icon.svg';

const Strategies = ({ isMobile }) => (
    <section id="strategies" className="strategies-section">
        <div className="bg-wrap">
            <img src={strategiesBg} alt="Bg"/>
        </div>
        <div className="title-wrap animation">
            <h2>Choose your<br/> Strategy</h2>
        </div>
        <div className="items-wrap">
            <div className={`item item-1 ${isMobile ? 'animation' : ''}`}>
                <div className="img-wrap">
                    <img src={strategiesItemOne} alt="Item"/>
                </div>
                <div className="description-wrap">
                    <h4>Degen</h4>
                    <p>Aims to find the next gem. Will lose in most plays. Will win big in others. Ge 10 tokens, aiming
                        to make at least one 10x.</p>
                </div>
                <div className="info-wrap">
                    <h4>Degen</h4>
                    <ul>
                        <li>
                            <h6>Take profit style:</h6>
                            <p>All or nothing!</p>
                        </li>
                        <li>
                            <h6>Derisk:</h6>
                            <p>What is that?</p>
                        </li>
                        <li>
                            <h6>Balance:</h6>
                            <p>Largest part of its portfolio are microcaps</p>
                        </li>
                        <li>
                            <h6>Cut Loss:</h6>
                            <p>Diamond hands or if project rugs</p>
                        </li>
                        <li>
                            <h6>DCA:</h6>
                            <p>Buys microcaps in a regular basis</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div className={`item item-2 ${isMobile ? 'animation' : ''}`}>
                <div className="img-wrap">
                    <img src={strategiesItemTwo} alt="Item"/>
                </div>
                <div className="description-wrap">
                    <h4>Moderate</h4>
                    <p>A balanced profile that makes calculated risks and has moderate returns.</p>
                </div>
                <div className="info-wrap">
                    <h4>Moderate</h4>
                    <ul>
                        <li>
                            <h6>Take profit style:</h6>
                            <p>Calculated goals</p>
                        </li>
                        <li>
                            <h6>Derisk:</h6>
                            <p>Derisks before taking profit</p>
                        </li>
                        <li>
                            <h6>Balance:</h6>
                            <p>Strategic allocations for each mcap range</p>
                        </li>
                        <li>
                            <h6>Cut Loss:</h6>
                            <p>Willing to lose half of an investment</p>
                        </li>
                        <li>
                            <h6>DCA:</h6>
                            <p>DCA out after derisking</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div className={`item item-3 ${isMobile ? 'animation' : ''}`}>
                <div className="img-wrap">
                    <img src={strategiesItemThree} alt="Item"/>
                </div>
                <div className="description-wrap">
                    <h4>Conservative</h4>
                    <p>Cannot accept high fluctuations. Prefers to make a small, steady return.</p>
                </div>
                <div className="info-wrap">
                    <h4>Conservative</h4>
                    <ul>
                        <li>
                            <h6>Take profit style:</h6>
                            <p>Jeet</p>
                        </li>
                        <li>
                            <h6>Derisk:</h6>
                            <p>Takes profit before derisking</p>
                        </li>
                        <li>
                            <h6>Balance:</h6>
                            <p>Prefers blue-chip and stables</p>
                        </li>
                        <li>
                            <h6>Cut Loss:</h6>
                            <p>Not willing to lose much of its investment</p>
                        </li>
                        <li>
                            <h6>DCA:</h6>
                            <p>DCA in into blue-chips</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="btn-wrap">
            <a href="https://apescreener.app" target="_blank" rel="noreferrer" className="btn-main">
                <span className="text">Set Up Free Adviser Now</span>
                <span className="icon">
                    <img src={btnArrowIcon} alt="Icon"/>
                </span>
            </a>
        </div>
    </section>
);

export default Strategies;
