import ArrowRight from "../../assets/img/arrow-right-icon.svg";

const PartnershipIntegration = () => {
  return (
    <div className="partnership-integration">
      <div className="integration-section">
        {/* Blurred Circles Background */}
        <div className="background-circles">
          <div className="circle circle-green"></div>
          <div className="circle circle-yellow"></div>
          <div className="circle circle-pink"></div>
        </div>

        <div className="breadcrumb">
          <div className="breadcrumb-item">
            <a href="/">Home</a>
          </div>
          <div className="breadcrumb-separator">
            <img src={ArrowRight} alt="breadcrumb right separator" />
          </div>
          <div className="breadcrumb-item">
            <a href="/partnership">Partnerships</a>
          </div>
          <div className="breadcrumb-separator">
            <img src={ArrowRight} alt="breadcrumb right separator" />
          </div>
          <div className="breadcrumb-item active">Integration Partnership</div>
        </div>

        <div className="title-section">
          <div className="title-main">Integration</div>
          <div className="title-sub">Partnership</div>
        </div>

        <div className="apply-button">
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSenjvolkBYQ8-zFJbHayBx8Z_Fv2CcOWU71PjDSMSkUZ-hJHg/viewform?usp=dialog">
          <div className="button-text">Apply Now</div>
          </a>
        </div>
      </div>

      <div className="partnership-details">
        <div className="details-container">
          {/* About Model Section */}
          <div className="card">
            <div className="card-content">
              <p>
                Integration partnerships are created for production ready utility projects. The utility of the partner project must
                help either “building,” “growing,” or “capitalizing” a portfolio. The purpose is to expand the functionalities of ApeScreener, while giving the partner project more visibility.
                <br /> <br />
              </p>
              <p>Some examples of eligible projects:</p>
              <ol>
                <li>Research tools</li>
                <li>Trading indicators</li>
                <li>Funds or indexes of tokens</li>
                <li>Signal or trading bots (only custodial)</li>
                <li>Security indicators</li>
              </ol>
            </div>
          </div>

          {/* Requirements Section */}
          <div className="card">
            <div className="card-title">Requirements:</div>
            <div className="card-content">
              <ol>
                <li>
                  The project must provide a simple interface/indicator with a clear value proposition.
                </li>
                <li>The project must provide a well-documented API.</li>
                <li>The service must have an uptime of 99.9%.</li>
                <li>
                  The website of the partner project must have a link to https://apescreener.app/asset/[chainid]/[partner_token_address]
                </li>
                <li>
                  If the website or product of the partner contains a link to a charting tool, a link to https://apescreener.app/asset/[chainid]/[partner_token_address] must be added.
                </li>
                <li>
                  The holder that wants to receive the benefit must hold a
                  certain amount of the partner project.
                </li>
                <li>
                  If the project is a bot, it must include a link to ApeScreener.
                </li>
                <li>
                  The token of the project must be audited.
                </li>
              </ol>
            </div>
          </div>

          {/* Benefits Section */}
          <div className="card">
            <div className="card-title">Benefits:</div>
            <div className="card-content">
              <ol>
                <li>
                  Holders of a certain value of partner tokens will have access
                  to our holder tier.
                </li>
                <li>
                  We will integrate the API on our platform and provide an
                  elegant user interface.
                </li>
                <li>
                  A tooltip will be added next to the feature, indicating that
                  the feature is “powered by” [link to the partner’s website].
                </li>
                <li>
                  A tweet will be done from our official X account, announcing
                  the partnership.
                </li>
                <li>List the partner project in the partners page.</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnershipIntegration;
