import ArrowRight from "../../assets/img/arrow-right-icon.svg";

const PartnershipInvestment = () => {
  return (
    <div>
      <div className="investment-partnership">
        {/* Background Blurred Circles */}
        <div className="background-blur">
          <div className="circle purple" />
          <div className="circle blue" />
          <div className="circle green" />
        </div>

        {/* Breadcrumb Navigation */}
        <div className="breadcrumb">
          <div className="breadcrumb-item">
            <a href="/">Home</a>
          </div>
          <div className="breadcrumb-arrow">
            <img src={ArrowRight} alt="Arrow Right" />
          </div>
          <div className="breadcrumb-item">
            <a href="/partnership">Partnership</a>
          </div>
          <div className="breadcrumb-arrow">
            <img src={ArrowRight} alt="Arrow Right" />
          </div>
          <div className="breadcrumb-item active">Investment Partnership</div>
        </div>

        {/* Title Section */}
        <div className="title-section">
          <div className="title-main">Investment</div>
          <div className="title-sub">Partnership</div>
        </div>

        {/* Apply Button */}
        <div className="apply-button">
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSenjvolkBYQ8-zFJbHayBx8Z_Fv2CcOWU71PjDSMSkUZ-hJHg/viewform?usp=dialog">
          <div className="button-text">Apply Now</div>
          </a>
        </div>
      </div>

      <div className="about-model">
        <div className="content-wrapper">
          {/* About Model Card */}
          <div className="card about">
            <div className="card-content">
              Investment partnerships are intended for projects who want to add an extra utility to their token. By buying a certain amount of $APES and then burning it in behalf of their community, holders will get access to the holder tier.
            </div>
          </div>

          {/* Requirements Card */}
          <div className="card requirements">
            <div className="card-title">Requirements:</div>
            <div className="card-content">
              <ol>
                <li>
                For every 1000 holders unique addresses, the project must buy 1 ETH worth of $APES and burn it. Projects must top-up the amount every new 1000 holders.
                <br/>Example:
                <ul>
                <li>1 - 1000 holders: 1 ETH</li>
                <li>1001 - 2000 holders: 2 ETH</li>
                <li>2001 - 3000 holders: 3 ETH</li>
                </ul>
    
                </li>
                <li>
                  The holder that wants to receive the benefit, must hold a
                  certain amount of the partner project.
                </li>
                <li>
                  The website of the partner project must have a link to{" "}
                  <a href="https://apescreener.app/asset/[chainid]/[partner_token_address]">
                    https://apescreener.app/asset/[chainid]/[partner_token_address]
                  </a>
                </li>
              </ol>
            </div>
          </div>

          {/* Benefits Card */}
          <div className="card benefits">
            <div className="card-title">Benefits:</div>
            <div className="card-content">
              <ol>
                <li>
                  Holders of a certain value of partner tokens will have access
                  to our holder tier.
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnershipInvestment;
